@import "../../../assets/styles/variables";

.icon {
  font-size: 24px;
  user-select: none;
  width: 24px;
  height: 24px;
  display: inline-flex;
  vertical-align: middle;

  &--sm {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
  &--black{
    color: $black;
  }
  &--gray-500{
    color: $gray-500;
  }
  &--gray-400{
    color: $gray-400;
  }
  &--info{
    color: $info;
  }
  &--success{
    color: $success;
  }
  &--warning{
    color: $warning;
  }
}
